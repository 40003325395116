<template>
    <div>
		<b-modal ref="modalCardsVideo" size="lg" hide-footer>
            <template v-slot:modal-title>
				<template>
					{{ $t("action.envoyer") }}
				</template>
			</template>
            <div class="text-center">
                <iframe title="vimeo-player" :src="link" height="400px" width="100%" style="position:relative" frameborder="0" allowfullscreen></iframe>
                <a :href="link" target="_blank">{{ $t('global.new_tab') }} <font-awesome-icon :icon="['fal', 'external-link-square-alt']" /></a>
            </div>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
export default {
	name: "ModalCardsVideo",
	mixins: [],
	data () {
		return {
            link: ''
		}
	},
	methods: {
        openModal(link) {
            this.link = link
            this.$refs.modalCardsVideo.show()
        }
	}
}
</script>